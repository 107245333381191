(function () {
  'use strict';

  angular
    .module('neo.serverNotFound')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('serverNotFound', {
        templateUrl: 'server-not-found/server-not-found.tpl.html',
        controller: 'ServerNotFoundCtrl',
        controllerAs: 'vm'
      });
  }
}());
